<template>
	<form action="#" class="formLeads" @submit.prevent="sendMail">
		<div class="formField">
			<input type="text" name="nome" required v-model="nome" placeholder=" " />
			<label for="nome" class="floatLabel">Nome</label>
		</div>
		<div class="formField">
			<input type="text" name="sobrenome" required v-model="sobrenome" placeholder=" " />
			<label for="sobrenome" class="floatLabel">Sobrenome</label>
		</div>
		<div class="formField">
			<input type="email" name="email" required v-model="email" placeholder=" " />
			<label for="email" class="floatLabel">E-mail</label>
		</div>
		<div class="formField">
			<input type="text" name="empresa" required v-model="empresa" placeholder=" " />
			<label for="empresa" class="floatLabel">Empresa</label>
		</div>
		<div class="formField">
			<input type="text" name="endereco" required v-model="endereco" placeholder=" " />
			<label for="endereco" class="floatLabel">Endereço</label>
		</div>
		<div class="formField">
			<input type="text" name="complemento" v-model="complemento" placeholder=" " />
			<label for="complemento" class="floatLabel">Complemento</label>
		</div>
		<div class="formField flex50 selects">
			<div class="formField">
				<select name="estado" v-model="estado" @change="filtraCidade" required :class="{ selected: estado != 'Estado' }">
					<option value="Estado" selected disabled>Estado</option>
					<option v-for="(estado, index) in cidades.estados" :key="index" :value="estado.sigla">{{ estado.nome }}</option>
				</select>
				<!-- <label for="Estado" class="floatLabel">Estado</label> -->
			</div>
			<div class="formField">
				<select name="cidade" v-model="cidade" required :class="{ selected: cidade != 'Cidade' }">
					<option value="Cidade" selected disabled>Cidade</option>
					<option v-for="(cidade, index) in cidadesFiltrado" :key="index" :value="cidade">{{ cidade }}</option>
				</select>
				<!-- <label for="cidade" class="floatLabel">Cidade</label> -->
			</div>
		</div>
		<div class="formField flex50">
			<div class="formField">
				<input type="text" name="cep" required v-model="cep" placeholder=" " v-mask="'##.###-###'" />
				<label for="cep" class="floatLabel">CEP</label>
			</div>
			<div class="formField">
				<input type="text" name="telefone" required v-model="telefone" placeholder=" " v-mask="['(##) ####-####', '(##) #####-####']" />
				<label for="telefone" class="floatLabel">Telefone</label>
			</div>
		</div>
		<div class="formField">
			<textarea v-model="mensagem" placeholder=" " required :maxlength="msgLength"></textarea>
			<label for="cidade" class="floatLabel">Mensagem</label>
			<span class="charcount" :class="{ alert: mensagem.length > 800 }">{{ mensagem.length }} / {{ msgLength }}</span>
		</div>
		<div class="formField checkbox">
			<input type="checkbox" name="termos" v-model="isAccepted" required />
			<label for="termos" @click="isAccepted = !isAccepted"
				>Li e estou de acordo com a <a href="https://pneuscaminhao.goodyear.com.br/politica-de-privacidade" target="_blank">política de cookies</a>, assim como as
				<a href="https://pneuscaminhao.goodyear.com.br/politica-de-privacidade" target="_blank">políticas de privacidade</a>.</label
			>
		</div>
		<div class="formField checkbox">
			<input type="checkbox" name="promo" v-model="isAcceptedPromo" />
			<label for="promo" @click="isAcceptedPromo = !isAcceptedPromo">Aceito receber comunicações e promoções da Goodyear e suas marcas associadas.</label>
		</div>
		<div class="formField buttonGroup" :class="{ 'posting': posting }">
			<button type="submit" class="sendBtn">Enviar</button>
			<button type="button" class="cancelBtn" @click="clearForm">Cancelar</button>
		</div>
	</form>
</template>

<script>
	import { objCidades } from "../assets/js/cidades";
	import axios from "axios";
	export default {
		name: "ContactForm",
		data() {
			return {
				msgLength: 1000,
				cidades: objCidades,
				cidadesFiltrado: [],
				nome: "",
				sobrenome: "",
				endereco: "",
				email: "",
				cidade: "Cidade",
				estado: "Estado",
				mensagem: "",
				isAccepted: false,
				posting: false,
				retorno: "",
				posted: false,
				cep: "",
				telefone: "",
				complemento: "",
				empresa: "",
				isAccepted: false,
				isAcceptedPromo: false,
			};
		},
		methods: {
			filtraCidade() {
				this.cidade ? this.estado : (this.estado = "Estado"); // reseta o estado
				// filtra o array de cidades pelo estado selecionado
				let c = this.cidades.estados.filter((item) => {
					return item.sigla === this.estado;
				})[0].cidades;
				this.cidadesFiltrado = c;
			},
			clearForm() {
				this.nome = "";
				this.email = "";
				this.sobrenome = "";
				this.mensagem = "";
				this.endereco = "";
				this.cidade = "Cidade";
				this.estado = "Estado";
				this.cep = "";
				this.telefone = "";
				this.complemento = "";
				this.empresa = "";
			},
			sendMail() {
				if (this.nome && this.email && this.sobrenome && this.mensagem && this.endereco && this.empresa && this.cidade != "Cidade" && this.estado != "Estado") {
					this.posting = true;
					let payload = {
						nome: this.nome,
						sobrenome: this.sobrenome,
						endereco: this.endereco,
						email: this.email,
						cidade: this.cidade,
						estado: this.estado,
						mensagem: this.mensagem,
						cep: this.cep,
						telefone: this.telefone,
						complemento: this.complemento,
						empresa: this.empresa,
						aceiteTermos: this.isAccepted,
						aceitePromo: this.isAcceptedPromo,
					};
					axios
						.post(`https://api.pneuskelly.com.br/sendmail`, payload)
						.then((res) => {
							alert(`Obrigado pelo seu contato, ${this.nome}. Sua mensagem foi enviada com sucesso e responderemos o mais breve possível.`);
							this.clearForm();
							this.posting = false;
						})
						.catch((err) => {
							alert("Erro: " + err.data);
							console.log(err);
							this.email.retorno = err.data;
						});
				} else {
					alert("Preencha todos os campos obrigatórios");
				}
			},
		},
		created() {},
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	@import "../assets/sass/main.scss";
</style>
