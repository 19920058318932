export const en_us = {
	menu: {
		contact: "Contact Us",
		tires: "Tires",
		about: "About us",
		where_to_buy: "Where to buy?",
		terms: "Terms of use and privacy policy",
		follow: "Follow Goodyear",
	},
	fhb: {
		backsplashAltText: "Kelly Tires | Um bom negócio por um ótimo pneu.",
		title: {
			one: "A",
			bgGreen_1: "good deal",
			for: "for a",
			bgGreen_2: "great tire.",
		},
		subtitle: "The option you need and the benefit you want for your fleet",
	},
	tiresSection: {
		domenicanDisclaimer: "Does not apply to Dominican Republic",
		title: "Tires",
		subtitle: "Discover the Kelly tire line for trucks and buses",
		description: "A complete selection of cost-effective truck and bus tires for your fleet.",
		readMore: "Learn more",
		tireTypes: {
			MSAII: {
				name: "Armorsteel Kelly MSA II",
				description: "For mixed service, on steer, trailer and moderate traction axles.",
				link: "https://comercial.goodyearca.com/en/detalles-llantas?ph=02010453147",
			},
			MSDII: {
				name: "Armorsteel Kelly MSD II",
				description: "For mixed service, on drive axles",
				link: "https://comercial.goodyearca.com/en/detalles-llantas?ph=02010453148",
			},
			KS461: {
				name: "Armorsteel Kelly KS461",
				description: "Versatile tire in regional and urban service, for steer, trailer and moderate traction axles.",
				link: "https://comercial.goodyearca.com/en/detalles-llantas?ph=02010450667",
			},
			KS481: {
				name: "Armorsteel Kelly KS481",
				description: "Versatile tire in regional and urban service, for traction axle.",
				link: "https://comercial.goodyearca.com/en/detalles-llantas?ph=02010450668",
			},
		},
	},
	bannersAltText: {
		first: "servicio mixto",
		second: "servicio regional",
	},
	aboutSection: {
		title: "About us",
		subtitle: "Kelly Tires",
		text: `<p>
		When it comes to tires, no one has been in the market for as long as Kelly Tires. Our legacy began in 1894, in Springfield, Ohio, United States, with the manufacture of solid rubber wheels for buggies and carriages. Since then, we have grown and now offer a <b>complete line of tires for cars, trucks</b> and commercial applications. Our slogan "A good deal for a great tire" - is as relevant today as it was more than 120 years ago.
      </p>

	  <p>

	  With Kelly, you can find <b>quality tires designed and produced in the Americas</b>, with excellent value for our customers. This philosophy was what led Goodyear Tire & Rubber company to buy Kelly Tires in 1935, and we are proud to remain a wholly owned subsidiary of this world leader. Kelly tires are manufactured by Goodyear in Brazil and United States and are available through the network of official Goodyear dealers. See the <a
	  href="https://trucktires.goodyearca.com/en/garantia-de-llantas"
	  target="_blank"
	  >warranty terms 
        </a
        >.
      </p>`,
	},
	newsletter: {
		title: "Assine a nossa Newsletter",
		labels: {
			name: "Nome",
			email: "E-mail",
		},
		legalText: `Ao clicar, você aceita os termos da
        <a
          href="https://pneuscaminhao.goodyear.com.br/politica-de-privacidade"
          target="_blank"
          >política de privacidade</a
        >, bem como receber comunicações da Goodyear e suas marcas
        associadas.`,
	},
	contactForm: {
		buttons: {
			send: "enviar",
			cancel: "cancelar",
		},
		labels: {
			firstName: "Nome",
			lastName: "Sobrenome",
			email: "E-mail",
			company: "Empresa",
			address: "Endereço",
			address_2: "Complemento",
			state: "Estado",
			city: "Cidade",
			zipCode: "CEP",
			phone: "Telefone",
			message: "Mensagem",
		},
		checks: {
			cookies: `Li e estou de acordo com a <a href="https://pneuscaminhao.goodyear.com.br/politica-de-privacidade" target="_blank">política de cookies</a>, assim como as
            <a href="https://pneuscaminhao.goodyear.com.br/politica-de-privacidade" target="_blank">políticas de privacidade</a>.`,
			promo: "Aceito receber comunicações e promoções da Goodyear e suas marcas associadas.",
		},
	},
	formSection: {
		title: "Contact Us",
		desc_1: "We are very proud of our products and our relationship with special customers like you",
		desc_2: "Please contact us with any questions or concerns about:",
		tireIcon: "Product Questions",
		atendantIcon: "Warranty assistance",
		tagIcon: "Promotion information",
		consultas: "General Questions",
	},
	footerSection: {
		copyright: "© 2020 Kelly Tires",
		terms_conditions: "Terms of use and privacy policy",
		follow_us: "Follow Goodyear",
		links: {
			linkedin: "",
			facebook: "https://www.facebook.com/goodyearcamcar",
			instagram: "",
			twitter: "",
		},
	},
	cookieMessage: "",
	terms_link: "https://comercial.goodyearca.com/en/terminos-uso-politica-privacidad",
	contact_link: "https://comercial.goodyearca.com/en/Comunicate-con-Nosotros",
	where_to_buy_link: "https://comercial.goodyearca.com/en/encuentre-distribuidor",
};
