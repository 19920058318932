import { pt_br } from "./pt_br";
import { en_us } from "./en_us";

import { es_ag } from "./es_ag";
import { es_ec } from "./es_ec";
import { es_pe } from "./es_pe";
import { es_cam } from "./es_cam";
import { es_mx } from "./es_mx";
import { es_col } from "./es_col";
import { es_ch } from "./es_ch";
import { es_up } from "./es_up";

export const languages = {
	"pt_br": pt_br,
	"en_us": en_us,
	"es_ag": es_ag,
	"es_ec": es_ec,
	"es_pe": es_pe,
	"es_cam": es_cam,
	"es_mx": es_mx,
	"es_col": es_col,
	"es_ch": es_ch,
	"es_up": es_up,
};
