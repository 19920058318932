<template>
	<section class="linha tireTypes">
		<div class="container">
			<div class="tire">
				<img src="../assets/images/pneus/msaii2x.png" :alt="languageStrings[language].tiresSection.tireTypes.MSAII.name" />
				<span class="tireName">{{ languageStrings[language].tiresSection.tireTypes.MSAII.name }}</span>
				<p>
					{{ languageStrings[language].tiresSection.tireTypes.MSAII.description }}
				</p>
				<a v-if="languageStrings[language].tiresSection.tireTypes.MSAII.link" :href="languageStrings[language].tiresSection.tireTypes.MSAII.link" target="_blank">{{
					languageStrings[language].tiresSection.readMore
				}}</a>
			</div>
			<div class="tire">
				<img src="../assets/images/pneus/msdii2x.png" :alt="languageStrings[language].tiresSection.tireTypes.MSDII.name" />
				<span class="tireName">{{ languageStrings[language].tiresSection.tireTypes.MSDII.name }}</span>
				<p>
					{{ languageStrings[language].tiresSection.tireTypes.MSDII.description }}
				</p>
				<a v-if="languageStrings[language].tiresSection.tireTypes.MSDII.link" :href="languageStrings[language].tiresSection.tireTypes.MSDII.link" target="_blank">{{
					languageStrings[language].tiresSection.readMore
				}}</a>
			</div>
			<div class="tire">
				<img src="../assets/images/pneus/ks4612x.png" :alt="languageStrings[language].tiresSection.tireTypes.KS461.name" />
				<span class="tireName">{{ languageStrings[language].tiresSection.tireTypes.KS461.name }}</span>
				<p>
					{{ languageStrings[language].tiresSection.tireTypes.KS461.description }}
				</p>
				<a v-if="languageStrings[language].tiresSection.tireTypes.KS461.link" :href="languageStrings[language].tiresSection.tireTypes.KS461.link" target="_blank">{{
					languageStrings[language].tiresSection.readMore
				}}</a>
			</div>
			<div class="tire">
				<img src="../assets/images/pneus/ks4812x.png" :alt="languageStrings[language].tiresSection.tireTypes.KS481.name" />
				<span class="tireName">{{ languageStrings[language].tiresSection.tireTypes.KS481.name }}</span>
				<p>{{ languageStrings[language].tiresSection.tireTypes.KS481.description }}</p>
				<a v-if="languageStrings[language].tiresSection.tireTypes.KS481.link" :href="languageStrings[language].tiresSection.tireTypes.KS481.link" target="_blank">{{
					languageStrings[language].tiresSection.readMore
				}}</a>
			</div>
		</div>
	</section>
</template>

<script>
	import { languages } from "../languages";
	export default {
		name: "Tires",
		data() {
			return {
				//language: "",
				languageStrings: languages,
			};
		},
		computed: {
			language() {
				return this.$store.state.language;
			},
		},
		created() {
			//this.language = localStorage.getItem("language");
		},
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	@import "../assets/sass/main.scss";
	.tireTypes {
		padding: 0 0 80px;
		@media #{$mobile} {
			padding: 0;
		}
		.container {
			display: flex;
			justify-content: space-between;
			@media #{$mobile} {
				flex-wrap: wrap;
				width: 100%;
			}
			.tire {
				width: 24%;
				border-radius: 0 15px 0 0;
				background: #e0e0e0;
				padding: 0 30px 100px;
				position: relative;
				transition: 0.3s ease;
				z-index: 1;
				@media #{$mobile} {
					font-size: 0.8rem;
					padding: 0 15px 80px;
				}
				@media #{$mobile} {
					width: 49%;
					margin: 0 0 70px;
				}
				&:hover {
					transform: scale(1.15);
					//margin: 0 30px;
					box-shadow: 0 0 10px rgba(#000, 0.4);
					z-index: 3;
				}
				img {
					width: 90%;
					display: block;
					margin: -40px auto 0;
				}
				.tireName {
					font-size: 1.7rem;
					font-weight: 700;
					line-height: 1.1em;
					color: #4e4e4e;
					display: block;
					margin-bottom: 20px;
					@media #{$mobile} {
						font-size: 1.2rem;
					}
				}
				p {
					color: #4e4e4e;
					//margin: 0 0 30px;
					@media #{$mobile} {
						font-size: 0.8rem;
					}
				}
				a {
					display: inline-block;
					padding: 15px 35px;
					color: $branco;
					background: $defGreen;
					text-decoration: none;
					position: absolute;
					bottom: 30px;
					left: 30px;
					transition: 0.3s;
					&:hover {
						opacity: 0.6;
					}
					@media #{$mobile} {
						font-size: 0.8rem;
						bottom: 15px;
						left: 15px;
					}
				}
			}
		}
	}
</style>
