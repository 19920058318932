<template>
	<form action="#" @submit.prevent="sendMail" class="formLeads">
		<div class="formField">
			<input type="text" name="nome" required v-model="nome" placeholder=" " />
			<label for="nome" class="floatLabel">{{ languageStrings[language].newsletter.labels.name }}</label>
		</div>

		<!-- Sobrenome não era um campo oficial inicialmente portanto não tem nos arquivos de tradução de outros idiomas -->

		<div class="formField">
			<input type="text" name="sobrenome" required v-model="sobrenome" placeholder=" " />
			<label for="sobrenome" class="floatLabel">{{ languageStrings[language].newsletter.labels.lastName }}</label>
		</div>
		<div class="formField">
			<input type="email" name="email" required v-model="email" placeholder=" " />
			<label for="email" class="floatLabel">{{ languageStrings[language].newsletter.labels.email }}</label>
		</div>

		<div class="formField checkbox">
			<input type="checkbox" name="termos" v-model="isAccepted" style="visibility: hidden" />

			<label for="termos" v-html="languageStrings[language].newsletter.legalText"></label>
		</div>
		<div class="formField button" :class="{ posting: posting }">
			<button type="submit" class="sendBtn">Assinar</button>
		</div>
	</form>
</template>

<script>
	import axios from "axios";
	import { languages } from "../languages";
	export default {
		name: "LeadForm",
		data() {
			return {
				//language: "",
				languageStrings: languages,
				nome: "",
				email: "",
				sobrenome: "",
				isAccepted: false,
				isAcceptedPromo: false,
				posting: false,
				errorList: {
					nome: "Por favor, preencha seu nome",
					sobrenome: "Por favor, preencha seu sobrenome",
					email: "Por favor, preencha seu email",
					termos: "Por favor, aceite a política de privacidade",
					promo: "Por favor, aceite o box para receber as promoções",
				},
			};
		},
		methods: {
			clearForm() {
				this.nome = "";
				this.sobrenome = "";
				this.email = "";
				this.isAccepted = false;
				this.isAcceptedPromo = false;
			},
			mostraErro(erro) {
				alert(this.errorList[erro]);
				this.posting = false;
			},
			sendMail() {
				if (!this.nome) return this.mostraErro("nome");
				if (!this.sobrenome) return this.mostraErro("sobrenome");
				if (!this.email) return this.mostraErro("email");
				// if (!this.isAccepted) return this.mostraErro("termos");
				// if (!this.isAcceptedPromo) return this.mostraErro("promo");

				this.posting = true;
				let payload = {
					firstName: this.nome,
					lastName: this.sobrenome,
					email: this.email,
				};
				axios
					.post(`https://api.pneuskelly.com.br/sendnews`, payload)
					.then((res) => {
						alert(`Obrigado por se inscrever na Newsletter, ${this.nome}.`);
						this.clearForm();
						this.posting = false;
					})
					.catch((err) => {
						alert("Erro: " + err);
						console.log(err);
					});
			},
		},
		created() {
			//this.language = localStorage.getItem("language");
		},
		computed: {
			language() {
				return this.$store.state.language;
			},
		},
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	@import "../assets/sass/main.scss";
</style>
