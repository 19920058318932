import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueSmoothScroll from "vue2-smooth-scroll";
import VueTheMask from "vue-the-mask";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faLinkedinIn, faInstagram, faFacebookF, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import VueGtm from "vue-gtm";
Vue.use(VueGtm, {
	id: "GTM-TNM4JWB",
	defer: false,
	enabled: true,
	debug: true,
	loadScript: true,
	vueRouter: router,
	ignoredViews: [],
	trackOnNextTick: false,
});

library.add(faLinkedinIn);
library.add(faInstagram);
library.add(faFacebookF);
library.add(faTimes);
library.add(faTwitter);

Vue.use(VueTheMask);
Vue.use(VueSmoothScroll, {
	duration: 700,
	offset: -75,
	updateHistory: false,
	easingFunction: "easeOutQuad",
});

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: (h) => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount("#app");
