export const pt_br = {
	menu: {
		contact: "Fale Conosco",
		tires: "Pneus",
		about: "Quem Somos",
		where_to_buy: "Onde Comprar",
		terms: "Termos e Condições",
		follow: "Acompanhe a Goodyear nas redes sociais",
	},
	fhb: {
		backsplashAltText: "Kelly Tires | Um bom negócio por um ótimo pneu.",
		title: {
			one: "Um",
			bgGreen_1: "bom negócio",
			for: "por um",
			bgGreen_2: "ótimo pneu.",
		},
		subtitle: "A opção que você precisa e o benefício que você quer para sua frota",
	},
	tiresSection: {
		title: "Pneus",
		subtitle: "Conheça a linha de pneus Kelly para Caminhões e Ônibus",
		description: "Uma seleção completa de pneus para caminhão e ônibus com um ótimo custo benefício para sua frota.",
		readMore: "Saiba Mais",
		tireTypes: {
			MSAII: {
				name: "Armorsteel Kelly MSA II",
				description: "Para serviço misto, em eixos direcionais, livres e de tração moderada.",
				link: "https://pneuscaminhao.goodyear.com.br/detalhes-de-pneus?ph=02010453147",
			},
			MSDII: {
				name: "Armorsteel Kelly MSD II",
				description: "Para serviço misto, em eixos de tração.",
				link: "https://pneuscaminhao.goodyear.com.br/detalhes-de-pneus?ph=02010453148",
			},
			KS461: {
				name: "Armorsteel Kelly KS461",
				description: "Pneu versátil no serviço regional e urbano, para eixos direcionais, livres e de tração moderada.",
				link: "https://pneuscaminhao.goodyear.com.br/detalhes-de-pneus?ph=02010450667",
			},
			KS481: {
				name: "Armorsteel Kelly KS481",
				description: "Pneu versátil no serviço regional e urbano, para eixos de tração.",
				link: "https://pneuscaminhao.goodyear.com.br/detalhes-de-pneus?ph=02010450668",
			},
		},
	},
	bannersAltText: {
		first: "Serviço Misto",
		second: "Serviço Regional",
	},
	aboutSection: {
		title: "Quem Somos",
		subtitle: "A Kelly Tires",
		text: `<p>
        Quando se trata de pneus, ninguém está no mercado há tanto tempo
        quanto a Kelly Tires. Nosso legado começou em 1894, em
        Springfield, Ohio, Estados Unidos, com a fabricação de rodas de
        borracha maciça para buggies e carruagens. Desde então, crescemos
        e agora oferecemos uma
        <b>linha completa de pneus para carros, caminhões</b> e aplicações
        comerciais. Nosso slogan "Um bom negócio por um ótimo pneu" - é
        tão relevante hoje quanto era há mais de 120 anos.
      </p>

      <p>
        Com o Kelly, você pode encontrar
        <b>pneus de qualidade projetados e produzidos nas Américas</b>,
        com excelente valor para nossos clientes. Essa filosofia foi o que
        levou a Goodyear Tire & Rubber company a comprar a Kelly Tires em
        1935, e estamos orgulhosos de continuar sendo uma filial de
        propriedade integral deste líder mundial. Os pneus Kelly são
        fabricados pela Goodyear no Brasil e estão disponíveis através da
        rede de revendedores oficiais Goodyear. Consulte os termos de
        garantia
        <a
          href="https://pneuscaminhao.goodyear.com.br/garantia-de-pneus"
          target="_blank"
          >aqui</a
        >.
      </p>`,
	},
	newsletter: {
		title: "Assine a nossa Newsletter",
		labels: {
			name: "Nome",
			email: "E-mail",
			lastName: "Sobrenome",
		},
		legalText: `Ao clicar, você aceita os termos da
        <a
          href="https://pneuscaminhao.goodyear.com.br/politica-de-privacidade"
          target="_blank"
          >política de privacidade</a
        >, bem como receber comunicações da Goodyear e suas marcas
        associadas.`,
	},
	contactForm: {
		buttons: {
			send: "enviar",
			cancel: "cancelar",
		},
		labels: {
			firstName: "Nome",
			lastName: "Sobrenome",
			email: "E-mail",
			company: "Empresa",
			address: "Endereço",
			address_2: "Complemento",
			state: "Estado",
			city: "Cidade",
			zipCode: "CEP",
			phone: "Telefone",
			message: "Mensagem",
		},
		checks: {
			cookies: `Li e estou de acordo com a <a href="https://pneuscaminhao.goodyear.com.br/politica-de-privacidade" target="_blank">política de cookies</a>, assim como as
            <a href="https://pneuscaminhao.goodyear.com.br/politica-de-privacidade" target="_blank">políticas de privacidade</a>.`,
			promo: "Aceito receber comunicações e promoções da Goodyear e suas marcas associadas.",
		},
	},
	formSection: {
		title: "Fale Conosco",
		desc_1: "Temos muito orgulho dos nossos produtos e do nosso relacionamento com clientes especiais como você",
		desc_2: "Entre em contato conosco para dúvidas ou questões sobre:",
		tireIcon: "Consultas sobre produtos",
		atendantIcon: "Assistência em garantia",
		tagIcon: "Informações sobre promoção",
		consultas: "Consultas Gerais",
	},
	footerSection: {
		copyright: "© 2020 Kelly Tires",
		terms_conditions: "Termos e Condições",
		follow_us: "Acompanhe a Goodyear nas redes sociais",
		links: {
			linkedin: "https://www.linkedin.com/company/goodyear-truck-tires-brasil/",
			facebook: "https://www.facebook.com/goodyeartt",
			instagram: "https://www.instagram.com/goodyeartrucktires/",
		},
	},
	cookieMessage:
		"Nós armazenamos dados temporariamente para melhorar a sua experiência de navegação e recomendar conteúdo de seu interesse. Ao utilizar nossos serviços, você concorda com tal monitoramento.",
	terms_link: "https://pneuscaminhao.goodyear.com.br/politica-de-privacidade",
	contact_link: "",
	where_to_buy_link: "https://pneuscaminhao.goodyear.com.br/encontre-um-revendedor",
};
