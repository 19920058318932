<template>
	<div class="home" id="top">
		<h1 style="position: absolute; top: 0; left: 0; width: 0px; height: 0px; overflow: hidden; visibility: hidden; font-size: 1px;">
			Kelly Tires
		</h1>
		<MainNav />
		<fhb />
		<main>
			<section class="linha pneus" id="pneus">
				<div class="container">
					<p class="domenicanDisclaimer" v-if="language === 'en_us' || language === 'es_cam'">
						{{ languageStrings[language].tiresSection.domenicanDisclaimer }}
					</p>
					<br />
					<h3>{{ languageStrings[language].tiresSection.title }}</h3>
					<big class="subTitulo">{{ languageStrings[language].tiresSection.subtitle }}</big>
					<p>
						{{ languageStrings[language].tiresSection.description }}
					</p>
				</div>
			</section>
			<Tires />
			<section class="halfBanners linha">
				<div class="container">
					<span>
						<img src="../assets/images/banner-misto.jpg" :alt="languageStrings[language].bannersAltText.first" aria-hidden="true" />
					</span>
					<span>
						<img src="../assets/images/banner-regional.jpg" :alt="languageStrings[language].bannersAltText.second" aria-hidden="true" />
					</span>
				</div>
			</section>
			<section class="linha quemSomos" id="quemSomos">
				<div class="container">
					<div class="esq">
						<h3>{{ languageStrings[language].aboutSection.title }}</h3>
						<big class="subTitulo">{{ languageStrings[language].aboutSection.subtitle }}</big>
						<div v-html="languageStrings[language].aboutSection.text"></div>
					</div>
					<div class="dir">
						<img src="../assets/images/foto-quem-somos@2x.jpg" alt="Kelly Tires" aria-hidden="true" class="desk" />
						<img src="../assets/images/foto-quem-somos-mobile@2x.jpg" alt="Kelly Tires" aria-hidden="true" class="mob" />
					</div>
				</div>
			</section>
			<section class="linha leads" v-if="language === 'pt_br'">
				<div class="container">
					<big class="subTitulo centerTitle">{{ languageStrings[language].newsletter.title }}</big>
					<LeadForm />
				</div>
			</section>
			<section class="linha contato" :class="{ padTop: language !== 'pt_br' }" id="contato">
				<div class="container">
					<big class="subTitulo centerTitle">{{ languageStrings[language].formSection.title }}</big>
					<p class="alignCenter constrain marginBottom">
						{{ languageStrings[language].formSection.desc_1 }}
					</p>
					<p class="alignCenter constrain marginBottom black">
						{{ languageStrings[language].formSection.desc_2 }}
					</p>
					<div class="icons">
						<div class="icon">
							<div>
								<img src="../assets/images/form/4.png" alt="" aria-hidden="true" />
							</div>
							<span class="desc">{{ languageStrings[language].formSection.consultas }}</span>
						</div>
						<div class="icon">
							<div>
								<img src="../assets/images/form/1.png" alt="" aria-hidden="true" />
							</div>
							<span class="desc">{{ languageStrings[language].formSection.tireIcon }}</span>
						</div>
						<div class="icon">
							<div>
								<img src="../assets/images/form/2.png" alt="" aria-hidden="true" />
							</div>
							<span class="desc">{{ languageStrings[language].formSection.atendantIcon }}</span>
						</div>
						<div class="icon">
							<div>
								<img src="../assets/images/form/3.png" alt="" aria-hidden="true" />
							</div>
							<span class="desc">{{ languageStrings[language].formSection.tagIcon }}</span>
						</div>
					</div>
					<ContactForm v-if="language === 'pt_br'" />
				</div>
			</section>
			<!-- <section class="linha entreEmContato">
				<div class="container">
					<big class="subTitulo centerTitle">Entre em Contato</big>
					<p class="alignCenter constrain marginBottom">
						Illustration of the Kelly-Springfield tire plant in Akron Ohio, c. 1920. Kelly tires, the oldest tire brand in the U.S., was established in 1894. As part of the Goodyear family, the full
						Kelly inventory is available on
						<a href="https://goodyear.com" target="blank">Goodyear.com</a>. You’ll be able to shop all tires, see store locations, and more.
						<big class="telefone">1234-5678</big>
					</p>
				</div>
			</section> -->
		</main>
		<footer class="linha">
			<img src="../assets/images/img-rodape2x.jpg" alt="Ilustração" aria-hidden="true" class="fullWidth" />
			<div class="container">
				<h6>
					<img src="../assets/images/logo-verde@2x.png" alt="Kelly Tires" />
				</h6>
				<span class="cprt">{{ languageStrings[language].footerSection.copyright }}</span>
				<a :href="languageStrings[language].terms_link" target="_blank" class="termos">{{ languageStrings[language].footerSection.terms_conditions }}</a>
				<span class="acompanhe">{{ languageStrings[language].footerSection.follow_us }}</span>
				<div class="social" v-if="language !== 'es_up'">
					<a v-if="languageStrings[language].footerSection.links.linkedin" :href="languageStrings[language].footerSection.links.linkedin" title="LinkedIn" class="twitter icon" target="_blank"
						><font-awesome-icon :icon="['fab', 'linkedin-in']"
					/></a>
					<a v-if="languageStrings[language].footerSection.links.instagram" :href="languageStrings[language].footerSection.links.instagram" title="Instagram" class="insta icon" target="_blank"
						><font-awesome-icon :icon="['fab', 'instagram']"
					/></a>
					<a v-if="languageStrings[language].footerSection.links.facebook" :href="languageStrings[language].footerSection.links.facebook" title="Facebook" class="fb icon" target="_blank"
						><font-awesome-icon :icon="['fab', 'facebook-f']"
					/></a>
					<a
						style="margin-left: 30px;"
						v-if="languageStrings[language].footerSection.links.twitter"
						:href="languageStrings[language].footerSection.links.twitter"
						title="Twitter"
						class="fb icon"
						target="_blank"
						><font-awesome-icon :icon="['fab', 'twitter']"
					/></a>
				</div>

				<div class="social" v-else>
					<div class="up ur">
						<span>
							<img src="../assets/images/uy.png" alt="" />
						</span>
						<a v-if="languageStrings[language].footerSection.links_ur.linkedin" :href="languageStrings[language].footerSection.links_ur.linkedin" title="LinkedIn" class="twitter icon"
							><font-awesome-icon :icon="['fab', 'linkedin-in']"
						/></a>

						<a v-if="languageStrings[language].footerSection.links_ur.instagram" :href="languageStrings[language].footerSection.links_ur.instagram" title="Instagram" class="insta icon"
							><font-awesome-icon :icon="['fab', 'instagram']"
						/></a>

						<a v-if="languageStrings[language].footerSection.links_ur.facebook" :href="languageStrings[language].footerSection.links_ur.facebook" title="Facebook" class="fb icon"
							><font-awesome-icon :icon="['fab', 'facebook-f']"
						/></a>

						<a v-if="languageStrings[language].footerSection.links_ur.twitter" :href="languageStrings[language].footerSection.links_ur.twitter" title="Twitter" class="fb icon" target="_blank"
							><font-awesome-icon :icon="['fab', 'twitter']"
						/></a>
					</div>
					<div class="up py">
						<span>
							<img src="../assets/images/py.png" alt="" />
						</span>
						<a v-if="languageStrings[language].footerSection.links_py.linkedin" :href="languageStrings[language].footerSection.links_py.linkedin" title="LinkedIn" class="twitter icon"
							><font-awesome-icon :icon="['fab', 'linkedin-in']"
						/></a>

						<a v-if="languageStrings[language].footerSection.links_py.instagram" :href="languageStrings[language].footerSection.links_py.instagram" title="Instagram" class="insta icon"
							><font-awesome-icon :icon="['fab', 'instagram']"
						/></a>

						<a v-if="languageStrings[language].footerSection.links_py.facebook" :href="languageStrings[language].footerSection.links_py.facebook" title="Facebook" class="fb icon"
							><font-awesome-icon :icon="['fab', 'facebook-f']"
						/></a>

						<a v-if="languageStrings[language].footerSection.links_py.twitter" :href="languageStrings[language].footerSection.links_py.twitter" title="Twitter" class="fb icon" target="_blank"
							><font-awesome-icon :icon="['fab', 'twitter']"
						/></a>
					</div>
				</div>
			</div>
		</footer>
		<!-- 
      :buttonDecline='true'
      buttonDeclineText="Recusar"
    -->
		<cookie-law buttonText="Aceitar" @accept="consentCookies(true)" v-if="language === 'pt_br'">
			<div slot="message">
				{{ languageStrings[language].cookieMessage }}
			</div>
		</cookie-law>
	</div>
</template>

<script>
	// @ is an alias to /src
	import fhb from "@/components/FullHeightBanner.vue";
	import MainNav from "@/components/Menu.vue";
	import LeadForm from "@/components/LeadForm.vue";
	import ContactForm from "@/components/ContactForm.vue";
	import Tires from "@/components/TireTypes.vue";
	import CookieLaw from "vue-cookie-law";
	import { languages } from "../languages";

	export default {
		name: "Home",
		components: {
			fhb,
			MainNav,
			LeadForm,
			ContactForm,
			CookieLaw,
			Tires,
		},
		data() {
			return {
				languageStrings: languages,
			};
		},
		created() {
			//this.language = this.$store.state.language;
		},
		computed: {
			language() {
				return this.$store.state.language;
			},
		},
		methods: {
			consentCookies(consent) {
				if (!consent) return;
				console.log("consent?", consent);
				this.$gtm.trackEvent({
					event: "cookie_consent",
				});
			},
		},
	};
</script>

<style scoped lang="scss">
	@import "../assets/sass/main.scss";
	@import "../assets/sass/home.scss";
	@import "../assets/sass/footer.scss";
</style>

<style lang="scss">
	.esq p {
		margin: 0 0 25px;
		b {
			color: #009639;
			font-weight: 600;
		}
	}
</style>
