<template>
	<div class="fhb">
		<div class="imgBacksplash">
			<img src="../assets/images/banners/banner-novo.jpg" :alt="languageStrings[language].fhb.backsplashAltText" />
		</div>
		<div class="centerText">
			<h2>
				{{ languageStrings[language].fhb.title.one }}
				<span class="bgGreen"
					><span>{{ languageStrings[language].fhb.title.bgGreen_1 }}</span></span
				>
				{{ languageStrings[language].fhb.title.for }}
				<span class="bgGreen"
					><span>{{ languageStrings[language].fhb.title.bgGreen_2 }}</span></span
				>
			</h2>
			<p>{{ languageStrings[language].fhb.subtitle }}</p>
		</div>
		<a href="#pneus" class="setinha" v-smooth-scroll>
			<img src="../assets/images/setinha2x.png" alt="Rolar para baixo" />
		</a>
	</div>
</template>

<script>
	import { languages } from "../languages";
	export default {
		name: "fhb",
		data() {
			return {
				//language: "",
				languageStrings: languages,
			};
		},
		computed: {
			language() {
				return this.$store.state.language;
			},
		},
		created() {
			//this.language = localStorage.getItem("language");
		},
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	@import "../assets/sass/main.scss";
	.fhb {
		margin-top: 65px;
		width: 100%;
		height: 60vh;
		position: relative;
		@media #{$mobile} {
			margin-top: 0px;
			height: 70vh;
		}
		.imgBacksplash {
			width: 100%;
			height: 100%;
			position: relative;
			z-index: 0;
			img {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 100%;
				height: 100%;
				object-fit: cover;
				transform: translate(-50%, -50%);
				z-index: 1;
			}
			&:before {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: rgba(darken($defGreen, 10%), 1);
				mix-blend-mode: multiply;
				z-index: 2;
			}
		}
		.centerText {
			position: absolute;
			width: 100%;
			left: 0;
			top: 45%;
			transform: translateY(-50%);
			color: $branco;
			text-align: center;
			h2,
			p {
				max-width: 640px;
				margin: 0 auto;
			}
			h2 {
				@include fontSize(65px);
				line-height: 1.5em;
				&::selection {
					background: $defGreen;
				}
				@media #{$mobile} {
					@include fontSize(35px);
				}
				span.bgGreen {
					display: inline-block;
					position: relative;
					span {
						position: relative;
						z-index: 2;
						&::selection {
							background: $defGreen;
						}
					}
					&:before {
						content: "";
						display: block;
						width: 103%;
						height: 75px;
						position: absolute;
						background: $defGreen;
						top: 12px;
						left: -6px;
						z-index: 0;
						@media #{$mobile} {
							height: 35px;
						}
					}
				}
			}
			p {
				margin-top: 20px;
				line-height: 1.2em;
				color: $branco;
				&::selection {
					background: $defGreen;
				}
				@media #{$mobile} {
					font-size: 0.9rem;
					padding: 0 5%;
				}
			}
		}
		.setinha {
			display: inline-block;
			position: absolute;
			bottom: 3vh;
			left: 50%;
			transform: translateX(-50%);
			transition: 0.3s ease;
			&:hover {
				opacity: 0.6;
			}
			img {
				height: 40px;
			}
		}
	}
</style>
